import React, { useState, useEffect } from 'react';
import { Row, Col, Container, Form, Button } from 'react-bootstrap';

const Admin = () => {
  useEffect(() => {
    document.getElementById("root").classList.add("h-100");
    const html = document.querySelector('html');
    const body = document.querySelector('body');
    html.classList.add("h-100");
    body.classList.add("h-100");
  }, [])

  return (
    <div className='bg-dark h-100 overflow-hidden'>
      <Row className='mb-3'>
        <Col style={{ top: "40%", position: "absolute" }}>
          <h1 className="text-center text-white">Coming soon</h1>
          <h5 className="text-center text-white">Something great is on its way!</h5>
        </Col>
      </Row>
    </div>
  );
}
export default Admin;