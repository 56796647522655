import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Container, Spinner } from "react-bootstrap";
import moment from "moment-timezone";

import { api } from "../../services/api.js";
import DataTable from "../../components/table.tsx";

const TriggerLogs = () => {
  const [isLoading, setIsLoading] = useState(true);
  const tableData = useRef();

  useEffect(() => {
    onDataLoad();
  }, []);

  const columns = React.useMemo(
    () => [
      {
        accessorKey: "operation",
        cell: (info) => info.getValue(),
        header: () => <span>Operation</span>,
        footer: (props) => props.column.id,
      },
      {
        accessorKey: "contractName",
        cell: (info) => info.getValue(),
        header: () => <span>Contract Name</span>,
        footer: (props) => props.column.id,
      },
      {
        accessorKey: "qstCode",
        cell: (info) => info.getValue(),
        header: () => <span>QST Code</span>,
        footer: (props) => props.column.id,
      },
      {
        accessorKey: "tvCode",
        cell: (info) => info.getValue(),
        header: () => <span>TradingView Code</span>,
        footer: (props) => props.column.id,
      },
      {
        accessorKey: "price",
        cell: (info) => info.getValue(),
        header: () => <span>Price</span>,
        footer: (props) => props.column.id,
      },
      {
        accessorKey: "multiplier",
        cell: (info) => info.getValue(),
        header: () => <span>Multiplier</span>,
        footer: (props) => props.column.id,
      },
      {
        accessorKey: "createdDate",
        cell: (info) => {
          const momentObj = moment(info.getValue());
          const kualaLumpurMoment = momentObj.tz("Asia/Kuala_Lumpur");
          const formattedDatetime = kualaLumpurMoment.format(
            "YYYY-MM-DD HH:mm:ss"
          );
          return <span>{formattedDatetime}</span>;
        },
        header: () => <span>Date</span>,
        footer: (props) => props.column.id,
      },
    ],
    []
  );

  let timeoutId;
  function onDataLoad() {
    if (timeoutId) {
      clearTimeout(timeoutId); // Clear the timeout if it exists
    }

    setIsLoading(true);
    api
      .getData(`log/trigger`)
      .then((res) => {
        tableData.current = res;
      })
      .catch((err) => {
        console.log(err);
        alert(err.response.data.msg);
      })
      .finally(() => {
        setIsLoading(false);
      });

    timeoutId = setTimeout(() => {
      onDataLoad();
    }, 30000);
  }

  return (
    <>
      <Container className="h-100">
        <Row className="mt-3 mb-3">
          <Col>
            <h1 className="text-center">
              Trigger Logs{" "}
              <i
                style={{ cursor: "pointer" }}
                className="fa fa-refresh text-primary"
                onClick={() => !isLoading && onDataLoad()}
              />
            </h1>
          </Col>
        </Row>
        {isLoading === false ? (
          <>
            <Row>
              <Col className="overflow-x-auto">
                <DataTable data={tableData.current} columns={columns} />
              </Col>
            </Row>
          </>
        ) : (
          <Row>
            <Col className="text-center">
              <Spinner />
            </Col>
          </Row>
        )}
      </Container>
    </>
  );
};
export default TriggerLogs;
