import React, { useState, useEffect, useRef } from "react";
import {
  Row,
  Col,
  Container,
  Form,
  Button,
  Spinner,
  Modal,
  Table,
} from "react-bootstrap";

import { api } from "../../services/api";
import DataTable from "../../components/table.tsx";
import DateTimePicker from "react-datetime-picker";
import moment from "moment-timezone";

const Contracts = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const tableData = useRef();

  const [createModalShow, setCreateModalShow] = useState(false);
  const [formCreateName, setFormCreateName] = useState("");
  const [formCreateQST, setFormCreateQST] = useState("");
  const [formCreateTV, setFormCreateTV] = useState("");
  const [formCreateValid, setFormCreateValid] = useState(true);
  const [formCreateStopExecStartDate, setFormCreateStopExecStartDate] =
    useState();
  const [formCreateStopExecEndDate, setFormCreateStopExecEndDate] = useState();
  const [formCreateLMTTickSize, setFormCreateLMTTickSize] = useState();
  const [formCreateLMTTickDifference, setFormCreateLMTTickDifference] =
    useState();
  const [formCreateMKTTickSize, setFormCreateMKTTickSize] = useState();
  const [formCreateMKTTickDifference, setFormCreateMKTTickDifference] =
    useState();
  const [formCreateFailsafe, setFormCreateFailsafe] = useState(true);
  const [formCreateLMTExitTimeout, setFormCreateLMTExitTimeout] = useState();
  const [formCreateLMTEnterTimeout, setFormCreateLMTEnterTimeout] = useState();

  const [editModalShow, setEditModalShow] = useState(false);
  const [formEditID, setFormEditID] = useState("");
  const [formEditName, setFormEditName] = useState("");
  const [formEditQST, setFormEditQST] = useState("");
  const [formEditTV, setFormEditTV] = useState("");
  const [formEditValid, setFormEditValid] = useState(true);
  const [formEditStopExecStartDate, setFormEditStopExecStartDate] = useState();
  const [formEditStopExecEndDate, setFormEditStopExecEndDate] = useState();
  const [formEditLMTTickSize, setformEditLMTTickSize] = useState();
  const [formEditLMTTickDifference, setformEditLMTTickDifference] = useState();
  const [formEditMKTTickSize, setformEditMKTTickSize] = useState();
  const [formEditMKTTickDifference, setformEditMKTTickDifference] = useState();
  const [formEditFailsafe, setFormEditFailsafe] = useState(true);
  const [formEditLMTExitTimeout, setFormEditLMTExitTimeout] = useState();
  const [formEditLMTEnterTimeout, setFormEditLMTEnterTimeout] = useState();

  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const [formDeleteID, setFormDeleteID] = useState("");
  const [formDeleteName, setFormDeleteName] = useState("");
  const [formDeleteQST, setFormDeleteQST] = useState("");
  const [formDeleteTV, setFormDeleteTV] = useState("");

  const [scheduleModalShow, setScheduleModalShow] = useState(false);
  const [formScheduleID, setFormScheduleID] = useState("");
  const [formScheduleName, setFormScheduleName] = useState("");
  const [formScheduleQST, setFormScheduleQST] = useState("");
  const [formScheduleTV, setFormScheduleTV] = useState("");
  const [formScheduleNewQST, setFormScheduleNewQST] = useState("");
  const [formScheduleDetail, setFormScheduleDetail] = useState([]);
  const [formScheduleChangeDate, setFormScheduleChangeDate] = useState(Date());

  useEffect(() => {
    onDataLoad();
  }, []);

  const columns = React.useMemo(
    () => [
      {
        accessorKey: "name",
        cell: (info) => info.getValue(),
        header: () => <span>Name</span>,
        footer: (props) => props.column.id,
      },
      {
        accessorKey: "qstCode",
        header: () => "QST Code",
        footer: (props) => props.column.id,
      },
      {
        accessorKey: "tvCode",
        header: () => "TradingView Code",
        footer: (props) => props.column.id,
      },
      {
        accessorKey: "stopExecStartDateTime",
        header: () => <span>Stop Execute Start Date</span>,
        cell: (info) => {
          if (info.getValue() !== "" && info.getValue() !== undefined) {
            const momentObj = moment(info.getValue());
            const kualaLumpurMoment = momentObj.tz("Asia/Kuala_Lumpur");
            const formattedDatetime = kualaLumpurMoment.format(
              "YYYY-MM-DD HH:mm:ss"
            );
            return <span>{formattedDatetime}</span>;
          } else {
            return <span>N/A</span>;
          }
        },
        footer: (props) => props.column.id,
        enableColumnFilter: false,
      },
      {
        accessorKey: "stopExecEndDateTime",
        header: "Stop Execute End Date",
        cell: (info) => {
          if (info.getValue() !== "" && info.getValue() !== undefined) {
            const momentObj = moment(info.getValue());
            const kualaLumpurMoment = momentObj.tz("Asia/Kuala_Lumpur");
            const formattedDatetime = kualaLumpurMoment.format(
              "YYYY-MM-DD HH:mm:ss"
            );
            return <span>{formattedDatetime}</span>;
          } else {
            return <span>N/A</span>;
          }
        },
        footer: (props) => props.column.id,
        enableColumnFilter: false,
      },
      {
        accessorKey: "isValid",
        header: "Valid",
        cell: (info) => (info.getValue() === true ? "Yes" : "No"),
        footer: (props) => props.column.id,
        enableColumnFilter: false,
      },
      {
        accessorKey: "_id",
        cell: (info) => (
          <>
            <Button
              variant="info"
              size="sm"
              onClick={() => onEditClick(info.getValue())}
            >
              <i className="fa fa-edit"></i>
            </Button>
            <Button
              variant="secondary"
              size="sm"
              onClick={() => onScheduleClick(info.getValue())}
            >
              <i className="fa fa-calendar"></i>
            </Button>
            <Button
              variant="danger"
              size="sm"
              onClick={() => onDeleteClick(info.getValue())}
            >
              <i className="fa fa-trash"></i>
            </Button>
          </>
        ),
        header: () => <span>Action</span>,
        footer: (props) => props.column.id,
        enableColumnFilter: false,
      },
    ],
    []
  );

  function onDataLoad() {
    setIsLoading(true);
    api
      .getData(`contract/`)
      .then((res) => {
        tableData.current = res;
      })
      .catch((err) => {
        console.log(err);
        alert(err.response.data.msg);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  function onAddNew() {
    setFormCreateName("");
    setFormCreateQST("");
    setFormCreateTV("");
    setFormCreateLMTTickSize("N/A");
    setFormCreateLMTTickDifference("N/A");
    setFormCreateMKTTickSize("N/A");
    setFormCreateMKTTickDifference("N/A");
    setFormCreateValid(true);
    setFormCreateFailsafe(true);
    setFormCreateLMTExitTimeout("N/A");
    setFormCreateLMTEnterTimeout("N/A");
    setFormCreateStopExecStartDate();
    setFormCreateStopExecEndDate();
    setCreateModalShow(!createModalShow);
  }

  function onCreateModalSubmit() {
    setIsSubmitting(true);
    var error = false;
    if (formCreateName.trim().length === 0) {
      alert("Name is empty");
      error = true;
    } else if (formCreateQST.trim().length === 0) {
      alert("QST Code is empty");
      error = true;
    } else if (formCreateTV.trim().length === 0) {
      alert("TradingView Code is empty");
      error = true;
    } else if (formCreateStopExecStartDate !== "") {
      if (formCreateStopExecEndDate === "") {
        alert("Stop Execution End Date is empty");
        error = true;
      } else if (formCreateStopExecStartDate > formCreateStopExecEndDate) {
        alert(
          "Stop Execution Start Date not allowed to be larger than End Date"
        );
        error = true;
      }
    } else if (formCreateLMTTickSize.length === 0) {
      alert("Tick Size is empty");
      error = true;
    } else if (formCreateLMTTickDifference.length === 0) {
      alert("Tick Difference is empty");
      error = true;
    }

    if (error === true) {
      setIsSubmitting(false);
      return;
    }

    api
      .postData(`contract/`, {
        name: formCreateName,
        qstCode: formCreateQST,
        tvCode: formCreateTV,
        isValid: formCreateValid,
        positionFailsafe: formCreateFailsafe,
        stopExecStartDateTime: formCreateStopExecStartDate,
        stopExecEndDateTime: formCreateStopExecEndDate,
        tickSizeLMT:
          formCreateLMTTickSize === "N/A"
            ? 0
            : parseFloat(formCreateLMTTickSize),
        tickDifferenceLMT:
          formCreateLMTTickDifference === "N/A"
            ? 0
            : parseFloat(formCreateLMTTickDifference),
        tickSizeMKT:
          formCreateMKTTickSize === "N/A"
            ? 0
            : parseFloat(formCreateMKTTickSize),
        tickDifferenceMKT:
          formCreateMKTTickDifference === "N/A"
            ? 0
            : parseFloat(formCreateMKTTickDifference),
        LMTExitTimeout:
          formCreateLMTExitTimeout === "N/A"
            ? 0
            : parseFloat(formCreateLMTExitTimeout),
      })
      .then((res) => {
        if (res.success === true) {
          alert("Success");
          setCreateModalShow(false);
        } else {
          alert("Fail");
        }
      })
      .catch((err) => {
        console.log(err);
        alert(err.response.data);
      })
      .finally(() => {
        onDataLoad();
        setIsSubmitting(false);
      });
  }

  function onEditClick(id) {
    if (!tableData.current) {
      alert("Error selecting data. Table is empty");
      return;
    }
    var tmp = tableData.current.filter((x) => x._id === id);
    if (tmp.length === 0 || tmp.length > 1) {
      alert("Error selecting data.");
      return;
    }
    tmp = tmp[0];
    setFormEditID(tmp._id);
    setFormEditName(tmp.name);
    setFormEditQST(tmp.qstCode);
    setFormEditTV(tmp.tvCode);
    setFormEditValid(tmp.isValid);
    setFormEditLMTExitTimeout(tmp.LMTExitTimeout ? tmp.LMTExitTimeout : "N/A");
    setFormEditLMTEnterTimeout(
      tmp.LMTEnterTimeout ? tmp.LMTEnterTimeout : "N/A"
    );
    setFormEditFailsafe(tmp.positionFailsafe);
    setFormEditStopExecStartDate(tmp.stopExecStartDateTime);
    setFormEditStopExecEndDate(tmp.stopExecEndDateTime);
    setformEditLMTTickSize(tmp.tickSizeLMT ? tmp.tickSizeLMT : "N/A");
    setformEditLMTTickDifference(
      tmp.tickDifferenceLMT ? tmp.tickDifferenceLMT : "N/A"
    );
    setformEditMKTTickSize(tmp.tickSizeMKT ? tmp.tickSizeMKT : "N/A");
    setformEditMKTTickDifference(
      tmp.tickDifferenceMKT ? tmp.tickDifferenceMKT : "N/A"
    );
    setEditModalShow(true);
  }

  function onScheduleClick(id) {
    if (!tableData.current) {
      alert("Error selecting data. Table is empty");
      return;
    }
    var tmp = tableData.current.filter((x) => x._id === id);
    if (tmp.length === 0 || tmp.length > 1) {
      alert("Error selecting data.");
      return;
    }
    tmp = tmp[0];
    setFormScheduleID(tmp._id);
    setFormScheduleName(tmp.name);
    setFormScheduleQST(tmp.qstCode);
    setFormScheduleTV(tmp.tvCode);
    setFormScheduleChangeDate();
    setFormScheduleNewQST("");
    setFormScheduleDetail(tmp.schedule ? tmp.schedule : []);
    setScheduleModalShow(true);
  }

  function onDeleteClick(id) {
    if (!tableData.current) {
      alert("Error selecting data. Table is empty");
      return;
    }
    var tmp = tableData.current.filter((x) => x._id === id);
    if (tmp.length === 0 || tmp.length > 1) {
      alert("Error selecting data.");
      return;
    }
    tmp = tmp[0];
    setFormDeleteID(tmp._id);
    setFormDeleteName(tmp.name);
    setFormDeleteQST(tmp.qstCode);
    setFormDeleteTV(tmp.tvCode);
    setDeleteModalShow(true);
  }

  function onDeleteModalConfirm() {
    setIsSubmitting(true);
    api
      .deleteData(`contract/${formDeleteID}`)
      .then((res) => {
        if (res.success === true) {
          alert("Success");
          setDeleteModalShow(false);
        } else {
          alert("Fail");
        }
      })
      .catch((err) => {
        console.log(err);
        alert(err.response.data);
      })
      .finally(() => {
        onDataLoad();
        setIsSubmitting(false);
      });
  }

  function onEditModalSubmit() {
    setIsSubmitting(true);
    var error = false;
    if (formEditName.trim().length === 0) {
      alert("Name is empty");
      error = true;
    } else if (formEditQST.trim().length === 0) {
      alert("QST Code is empty");
      error = true;
    } else if (formEditTV.trim().length === 0) {
      alert("TradingView Code is empty");
      error = true;
    } else if (formEditStopExecStartDate !== "") {
      if (formEditStopExecEndDate === "") {
        alert("Stop Execution End Date is empty");
        error = true;
      } else if (formEditStopExecStartDate > formEditStopExecEndDate) {
        alert(
          "Stop Execution Start Date not allowed to be larger than End Date"
        );
        error = true;
      }
    } else if (formEditLMTTickSize.length === 0) {
      alert("Tick Size is empty");
      error = true;
    } else if (formEditLMTTickDifference.length === 0) {
      alert("Tick Difference is empty");
      error = true;
    }

    if (error === true) {
      setIsSubmitting(false);
      return;
    }

    api
      .putData(`contract/${formEditID}`, {
        name: formEditName,
        qstCode: formEditQST,
        tvCode: formEditTV,
        isValid: formEditValid,
        positionFailsafe: formEditFailsafe ? formEditFailsafe : false,
        stopExecStartDateTime: formEditStopExecStartDate
          ? formEditStopExecStartDate
          : "",
        stopExecEndDateTime: formEditStopExecEndDate
          ? formEditStopExecEndDate
          : "",
        tickSizeLMT:
          formEditLMTTickSize === "N/A" ? 0 : parseFloat(formEditLMTTickSize),
        tickDifferenceLMT:
          formEditLMTTickDifference === "N/A"
            ? 0
            : parseFloat(formEditLMTTickDifference),
        tickSizeMKT:
          formEditMKTTickSize === "N/A" ? 0 : parseFloat(formEditMKTTickSize),
        tickDifferenceMKT:
          formEditMKTTickDifference === "N/A"
            ? 0
            : parseFloat(formEditMKTTickDifference),
        LMTExitTimeout:
          formEditLMTExitTimeout === "N/A"
            ? 0
            : parseFloat(formEditLMTExitTimeout),
        LMTEnterTimeout:
          formEditLMTEnterTimeout === "N/A"
            ? 0
            : parseFloat(formEditLMTEnterTimeout),
      })
      .then((res) => {
        if (res.success === true) {
          alert("Success");
          setEditModalShow(false);
        } else {
          alert("Fail");
        }
      })
      .catch((err) => {
        console.log(err);
        alert(err.response.data);
      })
      .finally(() => {
        onDataLoad();
        setIsSubmitting(false);
      });
  }

  function onCreateFormLMTTickSizeChange(event) {
    const value = event.target.value;
    if (formCreateLMTTickSize === "N/A") {
      if (event.nativeEvent.data === ".") {
        setFormCreateLMTTickSize("0.");
      } else if (event.nativeEvent.data === "0") {
        setFormCreateLMTTickSize("0.");
      } else if (/^[1-9]$/.test(event.nativeEvent.data)) {
        setFormCreateLMTTickSize(event.nativeEvent.data);
      }
    } else if (value === "" || value === "0") {
      setFormCreateLMTTickSize("N/A");
    } else if (isNumeric(value) && value > 0) {
      setFormCreateLMTTickSize(value);
    } else if (value === ".") {
      setFormCreateLMTTickSize("0.");
    } else {
      alert("Invalid LMT Tick Size");
    }
  }

  function onEditFormLMTTickSizeChange(event) {
    const value = event.target.value;
    if (formEditLMTTickSize === "N/A") {
      if (event.nativeEvent.data === ".") {
        setformEditLMTTickSize("0.");
      } else if (event.nativeEvent.data === "0") {
        setformEditLMTTickSize("0.");
      } else if (/^[1-9]$/.test(event.nativeEvent.data)) {
        setformEditLMTTickSize(event.nativeEvent.data);
      }
    } else if (value === "" || value === "0") {
      setformEditLMTTickSize("N/A");
    } else if (isNumeric(value) && value > 0) {
      setformEditLMTTickSize(value);
    } else if (value === ".") {
      setformEditLMTTickSize("0.");
    } else {
      alert("Invalid LMT Tick Size");
    }
  }

  function onCreateFormLMTTickDifferenceChange(event) {
    const value = event.target.value;
    if (formCreateLMTTickDifference === "N/A") {
      if (event.nativeEvent.data === ".") {
        setFormCreateLMTTickDifference("0.");
      } else if (event.nativeEvent.data === "0") {
        setFormCreateLMTTickDifference("0.");
      } else if (/^[1-9]$/.test(event.nativeEvent.data)) {
        setFormCreateLMTTickDifference(event.nativeEvent.data);
      }
    } else if (value === "" || value === "0") {
      setFormCreateLMTTickDifference("N/A");
    } else if (isNumeric(value) && value > 0) {
      setFormCreateLMTTickDifference(value);
    } else if (value === ".") {
      setFormCreateLMTTickDifference("0.");
    } else {
      alert("Invalid LMT Tick Difference");
    }
  }

  function onEditFormLMTTickDifferenceChange(event) {
    const value = event.target.value;
    if (formEditLMTTickDifference === "N/A") {
      if (event.nativeEvent.data === ".") {
        setformEditLMTTickDifference("0.");
      } else if (event.nativeEvent.data === "0") {
        setformEditLMTTickDifference("0.");
      } else if (/^[1-9]$/.test(event.nativeEvent.data)) {
        setformEditLMTTickDifference(event.nativeEvent.data);
      }
    } else if (value === "" || value === "0") {
      setformEditLMTTickDifference("N/A");
    } else if (isNumeric(value) && value > 0) {
      setformEditLMTTickDifference(value);
    } else if (value === ".") {
      setformEditLMTTickDifference("0.");
    } else {
      alert("Invalid LMT Tick Difference");
    }
  }

  function onCreateFormMKTTickSizeChange(event) {
    const value = event.target.value;
    if (formCreateMKTTickSize === "N/A") {
      if (event.nativeEvent.data === ".") {
        setFormCreateMKTTickSize("0.");
      } else if (event.nativeEvent.data === "0") {
        setFormCreateMKTTickSize("0.");
      } else if (/^[1-9]$/.test(event.nativeEvent.data)) {
        setFormCreateMKTTickSize(event.nativeEvent.data);
      }
    } else if (value === "" || value === "0") {
      setFormCreateMKTTickSize("N/A");
    } else if (isNumeric(value) && value > 0) {
      setFormCreateMKTTickSize(value);
    } else if (value === ".") {
      setFormCreateMKTTickSize("0.");
    } else {
      alert("Invalid MKT Tick Size");
    }
  }

  function onCreateFormLMTExitTimeoutChange(event) {
    const value = event.target.value;
    if (formCreateLMTExitTimeout === "N/A") {
      if (event.nativeEvent.data === ".") {
        setFormCreateLMTExitTimeout("0.");
      } else if (event.nativeEvent.data === "0") {
        setFormCreateLMTExitTimeout("0.");
      } else if (/^[1-9]$/.test(event.nativeEvent.data)) {
        setFormCreateLMTExitTimeout(event.nativeEvent.data);
      }
    } else if (value === "" || value === "0") {
      setFormCreateLMTExitTimeout("N/A");
    } else if (isNumeric(value) && value > 0) {
      setFormCreateLMTExitTimeout(value);
    } else if (value === ".") {
      setFormCreateLMTExitTimeout("0.");
    } else {
      alert("Invalid LMT Exit Timeout value");
    }
  }

  function onCreateFormLMTEnterTimeoutChange(event) {
    const value = event.target.value;
    if (formCreateLMTEnterTimeout === "N/A") {
      if (event.nativeEvent.data === ".") {
        setFormCreateLMTEnterTimeout("0.");
      } else if (event.nativeEvent.data === "0") {
        setFormCreateLMTEnterTimeout("0.");
      } else if (/^[1-9]$/.test(event.nativeEvent.data)) {
        setFormCreateLMTEnterTimeout(event.nativeEvent.data);
      }
    } else if (value === "" || value === "0") {
      setFormCreateLMTEnterTimeout("N/A");
    } else if (isNumeric(value) && value > 0) {
      setFormCreateLMTEnterTimeout(value);
    } else if (value === ".") {
      setFormCreateLMTEnterTimeout("0.");
    } else {
      alert("Invalid LMT Enter Timeout value");
    }
  }

  function onEditFormLMTExitTimeoutChange(event) {
    const value = event.target.value;
    if (formEditLMTExitTimeout === "N/A") {
      if (event.nativeEvent.data === ".") {
        setFormEditLMTExitTimeout("0.");
      } else if (event.nativeEvent.data === "0") {
        setFormEditLMTExitTimeout("0.");
      } else if (/^[1-9]$/.test(event.nativeEvent.data)) {
        setFormEditLMTExitTimeout(event.nativeEvent.data);
      }
    } else if (value === "" || value === "0") {
      setFormEditLMTExitTimeout("N/A");
    } else if (isNumeric(value) && value > 0) {
      setFormEditLMTExitTimeout(value);
    } else if (value === ".") {
      setFormEditLMTExitTimeout("0.");
    } else {
      alert("Invalid LMT Exit Timeout value");
    }
  }

  function onEditFormLMTEnterTimeoutChange(event) {
    const value = event.target.value;
    if (formEditLMTEnterTimeout === "N/A") {
      if (event.nativeEvent.data === ".") {
        setFormEditLMTEnterTimeout("0.");
      } else if (event.nativeEvent.data === "0") {
        setFormEditLMTEnterTimeout("0.");
      } else if (/^[1-9]$/.test(event.nativeEvent.data)) {
        setFormEditLMTEnterTimeout(event.nativeEvent.data);
      }
    } else if (value === "" || value === "0") {
      setFormEditLMTEnterTimeout("N/A");
    } else if (isNumeric(value) && value > 0) {
      setFormEditLMTEnterTimeout(value);
    } else if (value === ".") {
      setFormEditLMTEnterTimeout("0.");
    } else {
      alert("Invalid LMT Enter Timeout value");
    }
  }

  function onEditFormMKTTickSizeChange(event) {
    const value = event.target.value;
    if (formEditMKTTickSize === "N/A") {
      if (event.nativeEvent.data === ".") {
        setformEditMKTTickSize("0.");
      } else if (event.nativeEvent.data === "0") {
        setformEditMKTTickSize("0.");
      } else if (/^[1-9]$/.test(event.nativeEvent.data)) {
        setformEditMKTTickSize(event.nativeEvent.data);
      }
    } else if (value === "" || value === "0") {
      setformEditMKTTickSize("N/A");
    } else if (isNumeric(value)) {
      setformEditMKTTickSize(value);
    } else if (value === ".") {
      setformEditMKTTickSize("0.");
    } else {
      alert("Invalid MKT Tick Size");
    }
  }

  function onCreateFormMKTTickDifferenceChange(event) {
    const value = event.target.value;
    if (formCreateMKTTickDifference === "N/A") {
      if (event.nativeEvent.data === ".") {
        setFormCreateMKTTickDifference("0.");
      } else if (event.nativeEvent.data === "0") {
        setFormCreateMKTTickDifference("0.");
      } else if (/^[1-9]$/.test(event.nativeEvent.data)) {
        setFormCreateMKTTickDifference(event.nativeEvent.data);
      }
    } else if (value === "" || value === "0") {
      setFormCreateMKTTickDifference("N/A");
    } else if (isNumeric(value) && value > 0) {
      setFormCreateMKTTickDifference(value);
    } else if (value === ".") {
      setFormCreateMKTTickDifference("0.");
    } else {
      alert("Invalid MKT Tick Difference");
    }
  }

  function onEditFormMKTTickDifferenceChange(event) {
    const value = event.target.value;
    if (formEditMKTTickDifference === "N/A") {
      if (event.nativeEvent.data === ".") {
        setformEditMKTTickDifference("0.");
      } else if (event.nativeEvent.data === "0") {
        setformEditMKTTickDifference("0.");
      } else if (/^[1-9]$/.test(event.nativeEvent.data)) {
        setformEditMKTTickDifference(event.nativeEvent.data);
      }
    } else if (value === "" || value === "0") {
      setformEditMKTTickDifference("N/A");
    } else if (isNumeric(value) && value > 0) {
      setformEditMKTTickDifference(value);
    } else if (value === ".") {
      setformEditMKTTickDifference("0.");
    } else {
      alert("Invalid MKT Tick Difference");
    }
  }

  function onScheduleModalSubmit() {
    api
      .putData(`contract/${formScheduleID}/schedule`, {
        data: formScheduleDetail,
      })
      .then((res) => {
        if (res.success === true) {
          alert("Success");
          setScheduleModalShow(false);
        } else {
          alert("Fail");
        }
      })
      .catch((err) => {
        console.log(err);
        alert(err.response.data);
      })
      .finally(() => {
        onDataLoad();
        setIsSubmitting(false);
      });
  }

  function onAddSchedule() {
    if (formScheduleNewQST.trim().length === 0) {
      alert("QST Code is empty");
      return;
    } else if (formScheduleChangeDate === "") {
      alert("Change Date is empty");
      return;
    }

    const momentObj = moment(formScheduleChangeDate);
    const kualaLumpurMoment = momentObj.tz("Asia/Kuala_Lumpur");

    if (
      formScheduleDetail.find(
        (x) =>
          moment(x.changeDate).toDate().toString() ===
          kualaLumpurMoment.toDate().toString()
      )
    ) {
      alert("Change Date already exists");
      return;
    } else if (kualaLumpurMoment < new Date()) {
      alert("Change Date is not allowed to be in the past");
      return;
    }

    setFormScheduleDetail(
      [
        ...formScheduleDetail,
        { qstCode: formScheduleNewQST, changeDate: kualaLumpurMoment },
      ].sort((a, b) => {
        return moment(a.changeDate).diff(moment(b.changeDate));
      })
    );

    // order form schedule detail
    // setFormScheduleDetail(
    //   formScheduleDetail.sort((a, b) => {
    //     return moment(a.changeDate, "DD-MM-YYYY").diff(
    //       moment(b.changeDate, "DD-MM-YYYY")
    //     );
    //   })
    // );
  }

  function onDeleteSchedule(index) {
    setFormScheduleDetail(formScheduleDetail.filter((_, i) => i !== index));
  }

  function isNumeric(value) {
    const number = Number(value);
    return (
      !isNaN(number) && // Check if the conversion result is a number
      number == value
    ); // Loose equality to allow "10" and 10 to both be considered numeric
  }

  return (
    <>
      <Container className="h-100">
        <Row className="mt-3 mb-3">
          <Col>
            <h1 className="text-center">
              Contracts{" "}
              <i
                style={{ cursor: "pointer" }}
                className="fa fa-refresh text-primary"
                onClick={() => !isLoading && onDataLoad()}
              />
            </h1>
          </Col>
        </Row>
        {isLoading === false ? (
          <>
            <Row className="mb-3">
              <Col>
                <Button size="sm" onClick={() => onAddNew()}>
                  Add New
                </Button>
              </Col>
            </Row>
            <Row>
              <Col className="overflow-x-auto">
                <DataTable data={tableData.current} columns={columns} />
              </Col>
            </Row>
            <>
              {/* Create Modal */}
              <Modal
                show={createModalShow}
                size="lg"
                onHide={() => setCreateModalShow(false)}
              >
                <Modal.Header closeButton>
                  <Modal.Title>Create new Contract</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Form>
                    <Form.Group className="mb-3" controlId="formCreateName">
                      <Row>
                        <Col lg="2" className="mt-1">
                          <Form.Label className="fw-bold">Name</Form.Label>
                        </Col>
                        <Col lg="8">
                          <Form.Control
                            type="text"
                            placeholder="Name"
                            value={formCreateName}
                            onChange={(e) => setFormCreateName(e.target.value)}
                            disabled={isSubmitting}
                          />
                        </Col>
                      </Row>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formCreateQST">
                      <Row>
                        <Col lg="2" className="mt-1">
                          <Form.Label className="fw-bold">QST Code</Form.Label>
                        </Col>
                        <Col lg="8">
                          <Form.Control
                            type="text"
                            placeholder="QST Code"
                            value={formCreateQST}
                            onChange={(e) => setFormCreateQST(e.target.value)}
                            disabled={isSubmitting}
                          />
                        </Col>
                      </Row>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formCreateTV">
                      <Row>
                        <Col lg="2" className="mt-1">
                          <Form.Label className="fw-bold">
                            TradingView Code
                          </Form.Label>
                        </Col>
                        <Col lg="8">
                          <Form.Control
                            type="text"
                            placeholder="TradingView Code"
                            value={formCreateTV}
                            onChange={(e) => setFormCreateTV(e.target.value)}
                            disabled={isSubmitting}
                          />
                        </Col>
                      </Row>
                    </Form.Group>
                    <Form.Group
                      className="mb-3"
                      controlId="formCreateLMTTickSize"
                    >
                      <Row>
                        <Col lg="2" className="mt-1">
                          <Form.Label className="fw-bold">
                            LMT Tick Size
                          </Form.Label>
                        </Col>
                        <Col lg="8">
                          <Form.Control
                            type="text"
                            placeholder="LMT Tick Size"
                            value={formCreateLMTTickSize}
                            onChange={(e) => onCreateFormLMTTickSizeChange(e)}
                            disabled={isSubmitting}
                          />
                        </Col>
                      </Row>
                    </Form.Group>
                    <Form.Group
                      className="mb-3"
                      controlId="formCreateLMTTickDifference"
                    >
                      <Row>
                        <Col lg="2" className="mt-1">
                          <Form.Label className="fw-bold">
                            LMT Tick Difference
                          </Form.Label>
                        </Col>
                        <Col lg="8">
                          <Form.Control
                            type="text"
                            placeholder="LMT Tick Difference"
                            value={formCreateLMTTickDifference}
                            onChange={(e) =>
                              onCreateFormLMTTickDifferenceChange(e)
                            }
                            disabled={isSubmitting}
                          />
                        </Col>
                      </Row>
                    </Form.Group>
                    <Form.Group
                      className="mb-3"
                      controlId="formCreateMKTTickSize"
                    >
                      <Row>
                        <Col lg="2" className="mt-1">
                          <Form.Label className="fw-bold">
                            MKT Tick Size
                          </Form.Label>
                        </Col>
                        <Col lg="8">
                          <Form.Control
                            type="text"
                            placeholder="MKT Tick Size"
                            value={formCreateMKTTickSize}
                            onChange={(e) => onCreateFormMKTTickSizeChange(e)}
                            disabled={isSubmitting}
                          />
                        </Col>
                      </Row>
                    </Form.Group>
                    <Form.Group
                      className="mb-3"
                      controlId="formCreateMKTTickDifference"
                    >
                      <Row>
                        <Col lg="2" className="mt-1">
                          <Form.Label className="fw-bold">
                            MKT Tick Difference
                          </Form.Label>
                        </Col>
                        <Col lg="8">
                          <Form.Control
                            type="text"
                            placeholder="MKT Tick Difference"
                            value={formCreateMKTTickDifference}
                            onChange={(e) =>
                              onCreateFormMKTTickDifferenceChange(e)
                            }
                            disabled={isSubmitting}
                          />
                        </Col>
                      </Row>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formCreateValid">
                      <Row>
                        <Col lg="2">
                          <Form.Label className="fw-bold">Is Valid</Form.Label>
                        </Col>
                        <Col lg="8">
                          <Form.Check
                            type="switch"
                            checked={formCreateValid}
                            onChange={(e) =>
                              setFormCreateValid(e.target.checked)
                            }
                          />
                        </Col>
                      </Row>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formCreateFailsafe">
                      <Row>
                        <Col lg="2">
                          <Form.Label className="fw-bold">
                            Position Failsafe
                          </Form.Label>
                        </Col>
                        <Col lg="8">
                          <Form.Check
                            type="switch"
                            checked={formCreateFailsafe}
                            onChange={(e) =>
                              setFormCreateFailsafe(e.target.checked)
                            }
                          />
                        </Col>
                      </Row>
                    </Form.Group>
                    <Form.Group
                      className="mb-3"
                      controlId="formCreateLMTEnterTimeout"
                    >
                      <Row>
                        <Col lg="2">
                          <Form.Label className="fw-bold">
                            Limit Order Enter Timeout (Minute)
                          </Form.Label>
                        </Col>
                        <Col lg="8">
                          <Form.Control
                            type="text"
                            placeholder="LMT Enter Timeout"
                            value={formCreateLMTEnterTimeout}
                            onChange={(e) =>
                              onCreateFormLMTEnterTimeoutChange(e)
                            }
                            disabled={isSubmitting}
                          />
                        </Col>
                      </Row>
                    </Form.Group>
                    <Form.Group
                      className="mb-3"
                      controlId="formCreateLMTExitTimeout"
                    >
                      <Row>
                        <Col lg="2">
                          <Form.Label className="fw-bold">
                            Limit Order Exit Timeout (Minute)
                          </Form.Label>
                        </Col>
                        <Col lg="8">
                          <Form.Control
                            type="text"
                            placeholder="LMT Exit Timeout"
                            value={formCreateLMTExitTimeout}
                            onChange={(e) =>
                              onCreateFormLMTExitTimeoutChange(e)
                            }
                            disabled={isSubmitting}
                          />
                        </Col>
                      </Row>
                    </Form.Group>
                    <Form.Group
                      className="mb-3"
                      controlId="formCreateStartDate"
                    >
                      <Row>
                        <Col lg="2" className="mt-1">
                          <Form.Label className="fw-bold">
                            Stop Execute Start Date
                          </Form.Label>
                        </Col>
                        <Col lg="8">
                          <DateTimePicker
                            onChange={setFormCreateStopExecStartDate}
                            value={formCreateStopExecStartDate}
                          />
                        </Col>
                      </Row>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formCreateStopDate">
                      <Row>
                        <Col lg="2" className="mt-1">
                          <Form.Label className="fw-bold">
                            Stop Execute End Date
                          </Form.Label>
                        </Col>
                        <Col lg="8">
                          <DateTimePicker
                            onChange={setFormCreateStopExecEndDate}
                            value={formCreateStopExecEndDate}
                          />
                        </Col>
                      </Row>
                    </Form.Group>
                  </Form>
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    variant="secondary"
                    onClick={() => setCreateModalShow(false)}
                    disabled={isSubmitting}
                  >
                    Close
                  </Button>
                  <Button
                    variant="primary"
                    onClick={() => onCreateModalSubmit()}
                    disabled={isSubmitting}
                  >
                    Save Changes
                  </Button>
                </Modal.Footer>
              </Modal>
              {/* Delete Modal */}
              <Modal
                show={deleteModalShow}
                size="lg"
                onHide={() => setDeleteModalShow(false)}
              >
                <Modal.Header closeButton>
                  <Modal.Title>Delete Contract</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <h5 className="mb-3">Are you sure to delete?</h5>
                  <h6 className="fw-normal">
                    Name: <span className="fw-semibold">{formDeleteName}</span>
                  </h6>
                  <h6 className="fw-normal">
                    QST Code:{" "}
                    <span className="fw-semibold">{formDeleteQST}</span>
                  </h6>
                  <h6 className="fw-normal">
                    TradingView Code:{" "}
                    <span className="fw-semibold">{formDeleteTV}</span>
                  </h6>
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    variant="secondary"
                    onClick={() => setDeleteModalShow(false)}
                    disabled={isSubmitting}
                  >
                    Close
                  </Button>
                  <Button
                    variant="primary"
                    onClick={() => onDeleteModalConfirm()}
                    disabled={isSubmitting}
                  >
                    Confirm
                  </Button>
                </Modal.Footer>
              </Modal>
              {/* Edit Modal */}
              <Modal
                show={editModalShow}
                size="lg"
                onHide={() => setEditModalShow(false)}
              >
                <Modal.Header closeButton>
                  <Modal.Title>Edit Contract</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Form>
                    <Form.Group className="mb-3" controlId="formEditName">
                      <Row>
                        <Col lg="2" className="mt-1">
                          <Form.Label className="fw-bold">Name</Form.Label>
                        </Col>
                        <Col lg="8">
                          <Form.Control
                            type="text"
                            placeholder="Name"
                            value={formEditName}
                            onChange={(e) => setFormEditName(e.target.value)}
                            disabled={isSubmitting}
                          />
                        </Col>
                      </Row>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formEditQST">
                      <Row>
                        <Col lg="2" className="mt-1">
                          <Form.Label className="fw-bold">QST Code</Form.Label>
                        </Col>
                        <Col lg="8">
                          <Form.Control
                            type="text"
                            placeholder="QST Code"
                            value={formEditQST}
                            onChange={(e) => setFormEditQST(e.target.value)}
                            disabled={isSubmitting}
                          />
                        </Col>
                      </Row>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formEditTV">
                      <Row>
                        <Col lg="2" className="mt-1">
                          <Form.Label className="fw-bold">
                            TradingView Code
                          </Form.Label>
                        </Col>
                        <Col lg="8">
                          <Form.Control
                            type="text"
                            placeholder="TradingView Code"
                            value={formEditTV}
                            onChange={(e) => setFormEditTV(e.target.value)}
                            disabled={isSubmitting}
                          />
                        </Col>
                      </Row>
                    </Form.Group>
                    <Form.Group
                      className="mb-3"
                      controlId="formEditLMTTickSize"
                    >
                      <Row>
                        <Col lg="2" className="mt-1">
                          <Form.Label className="fw-bold">
                            LMT Tick Size
                          </Form.Label>
                        </Col>
                        <Col lg="8">
                          <Form.Control
                            type="text"
                            placeholder="LMT Tick Size"
                            value={formEditLMTTickSize}
                            onChange={(e) => onEditFormLMTTickSizeChange(e)}
                            disabled={isSubmitting}
                          />
                        </Col>
                      </Row>
                    </Form.Group>
                    <Form.Group
                      className="mb-3"
                      controlId="formEditLMTTickDifference"
                    >
                      <Row>
                        <Col lg="2" className="mt-1">
                          <Form.Label className="fw-bold">
                            LMT Tick Difference
                          </Form.Label>
                        </Col>
                        <Col lg="8">
                          <Form.Control
                            type="text"
                            placeholder="LMT Tick Difference"
                            value={formEditLMTTickDifference}
                            onChange={(e) =>
                              onEditFormLMTTickDifferenceChange(e)
                            }
                            disabled={isSubmitting}
                          />
                        </Col>
                      </Row>
                    </Form.Group>
                    <Form.Group
                      className="mb-3"
                      controlId="formEditMKTTickSize"
                    >
                      <Row>
                        <Col lg="2" className="mt-1">
                          <Form.Label className="fw-bold">
                            MKT Tick Size
                          </Form.Label>
                        </Col>
                        <Col lg="8">
                          <Form.Control
                            type="text"
                            placeholder="MKT Tick Size"
                            value={formEditMKTTickSize}
                            onChange={(e) => onEditFormMKTTickSizeChange(e)}
                            disabled={isSubmitting}
                          />
                        </Col>
                      </Row>
                    </Form.Group>
                    <Form.Group
                      className="mb-3"
                      controlId="formEditMKTTickDifference"
                    >
                      <Row>
                        <Col lg="2" className="mt-1">
                          <Form.Label className="fw-bold">
                            MKT Tick Difference
                          </Form.Label>
                        </Col>
                        <Col lg="8">
                          <Form.Control
                            type="text"
                            placeholder="MKT Tick Difference"
                            value={formEditMKTTickDifference}
                            onChange={(e) =>
                              onEditFormMKTTickDifferenceChange(e)
                            }
                            disabled={isSubmitting}
                          />
                        </Col>
                      </Row>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formEditValid">
                      <Row>
                        <Col lg="2">
                          <Form.Label className="fw-bold">Is Valid</Form.Label>
                        </Col>
                        <Col lg="8">
                          <Form.Check
                            type="switch"
                            checked={formEditValid}
                            onChange={(e) => setFormEditValid(e.target.checked)}
                          />
                        </Col>
                      </Row>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formEditFailsafe">
                      <Row>
                        <Col lg="2">
                          <Form.Label className="fw-bold">
                            Position Failsafe
                          </Form.Label>
                        </Col>
                        <Col lg="8">
                          <Form.Check
                            type="switch"
                            checked={formEditFailsafe}
                            onChange={(e) =>
                              setFormEditFailsafe(e.target.checked)
                            }
                          />
                        </Col>
                      </Row>
                    </Form.Group>
                    <Form.Group
                      className="mb-3"
                      controlId="formEditLMTEnterTimeout"
                    >
                      <Row>
                        <Col lg="2">
                          <Form.Label className="fw-bold">
                            Limit Order Enter Timeout (Minute)
                          </Form.Label>
                        </Col>
                        <Col lg="8">
                          <Form.Control
                            type="text"
                            placeholder="LMT Enter Timeout"
                            value={formEditLMTEnterTimeout}
                            onChange={(e) => onEditFormLMTEnterTimeoutChange(e)}
                            disabled={isSubmitting}
                          />
                        </Col>
                      </Row>
                    </Form.Group>
                    <Form.Group
                      className="mb-3"
                      controlId="formEditLMTExitTimeout"
                    >
                      <Row>
                        <Col lg="2">
                          <Form.Label className="fw-bold">
                            Limit Order Exit Timeout (Minute)
                          </Form.Label>
                        </Col>
                        <Col lg="8">
                          <Form.Control
                            type="text"
                            placeholder="LMT Exit Timeout"
                            value={formEditLMTExitTimeout}
                            onChange={(e) => onEditFormLMTExitTimeoutChange(e)}
                            disabled={isSubmitting}
                          />
                        </Col>
                      </Row>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formEditStartDate">
                      <Row>
                        <Col lg="2" className="mt-1">
                          <Form.Label className="fw-bold">
                            Stop Execute Start Date
                          </Form.Label>
                        </Col>
                        <Col lg="8">
                          <DateTimePicker
                            onChange={setFormEditStopExecStartDate}
                            value={formEditStopExecStartDate}
                          />
                        </Col>
                      </Row>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formEditStopDate">
                      <Row>
                        <Col lg="2" className="mt-1">
                          <Form.Label className="fw-bold">
                            Stop Execute End Date
                          </Form.Label>
                        </Col>
                        <Col lg="8">
                          <DateTimePicker
                            onChange={setFormEditStopExecEndDate}
                            value={formEditStopExecEndDate}
                          />
                        </Col>
                      </Row>
                    </Form.Group>
                  </Form>
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    variant="secondary"
                    onClick={() => setEditModalShow(false)}
                    disabled={isSubmitting}
                  >
                    Close
                  </Button>
                  <Button
                    variant="primary"
                    onClick={() => onEditModalSubmit()}
                    disabled={isSubmitting}
                  >
                    Save Changes
                  </Button>
                </Modal.Footer>
              </Modal>
              {/* Schedule Modal */}
              <Modal
                show={scheduleModalShow}
                size="lg"
                onHide={() => setScheduleModalShow(false)}
              >
                <Modal.Header closeButton>
                  <Modal.Title>Contract Schedule</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Form>
                    <Form.Group className="mb-3" controlId="formScheduleName">
                      <Row>
                        <Col lg="2" className="mt-1">
                          <Form.Label className="fw-bold">Name</Form.Label>
                        </Col>
                        <Col lg="8">
                          <Form.Control
                            type="text"
                            placeholder="Name"
                            disabled={true}
                            value={formScheduleName}
                          />
                        </Col>
                      </Row>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formScheduleQST">
                      <Row>
                        <Col lg="2" className="mt-1">
                          <Form.Label className="fw-bold">
                            Current QST Code
                          </Form.Label>
                        </Col>
                        <Col lg="8">
                          <Form.Control
                            type="text"
                            disabled={true}
                            placeholder="QST Code"
                            value={formScheduleQST}
                          />
                        </Col>
                      </Row>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formScheduleTV">
                      <Row>
                        <Col lg="2" className="mt-1">
                          <Form.Label className="fw-bold">
                            TradingView Code
                          </Form.Label>
                        </Col>
                        <Col lg="8">
                          <Form.Control
                            type="text"
                            placeholder="TradingView Code"
                            disabled={true}
                            value={formScheduleTV}
                          />
                        </Col>
                      </Row>
                    </Form.Group>
                    <Form.Group
                      className="mb-3"
                      controlId="formScheduleChangeDate"
                    >
                      <Row>
                        <Col lg="2" className="mt-1">
                          <Form.Label className="fw-bold">
                            QST Code Schedule
                          </Form.Label>
                        </Col>
                        <Col lg="4">
                          <Form.Control
                            type="text"
                            placeholder="New QST Code"
                            onChange={(e) =>
                              setFormScheduleNewQST(e.target.value)
                            }
                            disabled={isSubmitting}
                            value={formScheduleNewQST}
                          />
                        </Col>
                        <Col lg="4">
                          <DateTimePicker
                            onChange={setFormScheduleChangeDate}
                            value={formScheduleChangeDate}
                            disabled={isSubmitting}
                          />
                        </Col>
                        <Col lg="2">
                          <Button
                            className="ms-1"
                            variant="primary"
                            size="sm"
                            onClick={() => onAddSchedule()}
                            disabled={isSubmitting}
                          >
                            Add Schedule
                          </Button>
                        </Col>
                      </Row>
                    </Form.Group>
                    <Row>
                      <Col>
                        <span className="fw-bold">Current Schedule</span>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Table striped bordered hover>
                          <thead>
                            <tr>
                              <th>QST Code</th>
                              <th>Change Date</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {formScheduleDetail.length === 0 && (
                              <tr>
                                <td colSpan="3">No Schedule</td>
                              </tr>
                            )}
                            {formScheduleDetail
                              .sort((a, b) => {
                                return (
                                  new Date(a.changeDate) -
                                  new Date(b.changeDate)
                                );
                              })
                              .map((x, index) => (
                                <tr key={index}>
                                  <td>{x.qstCode}</td>
                                  <td>
                                    {moment(x.changeDate).toDate().toString()}
                                  </td>
                                  <td>
                                    <Button
                                      variant="danger"
                                      size="sm"
                                      onClick={() => onDeleteSchedule(index)}
                                    >
                                      <i className="fa fa-trash"></i>
                                    </Button>
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </Table>
                      </Col>
                    </Row>
                  </Form>
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    variant="secondary"
                    onClick={() => setScheduleModalShow(false)}
                    disabled={isSubmitting}
                  >
                    Close
                  </Button>
                  <Button
                    variant="primary"
                    onClick={() => onScheduleModalSubmit()}
                    disabled={isSubmitting}
                  >
                    Save Changes
                  </Button>
                </Modal.Footer>
              </Modal>
            </>
          </>
        ) : (
          <Row>
            <Col className="text-center">
              <Spinner />
            </Col>
          </Row>
        )}
      </Container>
    </>
  );
};
export default Contracts;
