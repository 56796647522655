import React, { useState, useEffect, useRef } from "react";
import {
  Row,
  Col,
  Container,
  Form,
  Button,
  Spinner,
  Modal,
  Badge,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import Multiselect from "react-widgets/Multiselect";

import { api } from "../../services/api";
import DataTable from "../../components/table.tsx";

const Accounts = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const tableData = useRef();

  const strategyList = useRef();

  const [createModalShow, setCreateModalShow] = useState(false);
  const [formCreateAccount, setFormCreateAccount] = useState("");
  const [formCreateProvider, setFormCreateProvider] = useState("PTS");
  const [formCreateValid, setFormCreateValid] = useState(true);
  const [formCreateStrategyMode, setFormCreateStrategyMode] =
    useState("BLACKLIST");
  const [formCreateStrategyList, setFormCreateStrategyList] = useState([]);

  const [editModalShow, setEditModalShow] = useState(false);
  const [formEditID, setFormEditID] = useState("");
  const [formEditAccount, setFormEditAccount] = useState("");
  const [formEditProvider, setFormEditProvider] = useState("");
  const [formEditValid, setFormEditValid] = useState(true);
  const [formEditStrategyMode, setFormEditStrategyMode] = useState("BLACKLIST");
  const [formEditStrategyList, setFormEditStrategyList] = useState([]);

  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const [formDeleteID, setFormDeleteID] = useState("");
  const [formDeleteAccount, setFormDeleteAccount] = useState("");
  const [formDeleteProvider, setFormDeleteProvider] = useState("");

  useEffect(() => {
    onDataLoad();
  }, []);

  const columns = React.useMemo(
    () => [
      {
        accessorKey: "account",
        cell: (info) => info.getValue(),
        header: () => <span>Account</span>,
        footer: (props) => props.column.id,
      },
      {
        accessorKey: "provider",
        header: () => "Demo/Live",
        cell: (info) => {
          return (
            <Badge bg={info.getValue() === "PTS" ? "warning" : "success"}>
              {info.getValue() === "PTS" ? "DEMO" : "LIVE"}
            </Badge>
          );
        },
        footer: (props) => props.column.id,
      },
      {
        accessorKey: "strategy_mode",
        header: () => "Strategy Mode",
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
      },
      {
        accessorKey: "strategy_list",
        header: () => "Strategy List/Blacklist",
        cell: (info) => {
          return info.getValue().map((x, i) => (
            <h6 className="fw-normal" key={i}>
              {strategyList.current.find((y) => y._id === x)
                ? strategyList.current.find((y) => y._id === x).name
                : "Deleted"}
            </h6>
          ));
        },
        footer: (props) => props.column.id,
      },
      {
        accessorKey: "isValid",
        header: () => "Valid",
        cell: (info) => (info.getValue() === true ? "Yes" : "No"),
        footer: (props) => props.column.id,
      },
      // {
      //   accessorKey: "isActive",
      //   header: () => "Active",
      //   cell: (info) => {
      //     return (
      //       <Badge bg={info.getValue() === true ? "success" : "warning"}>
      //         {info.getValue() === true ? "Active" : "Inactive"}
      //       </Badge>
      //     );
      //   },
      //   footer: (props) => props.column.id,
      // },
      {
        accessorKey: "_id",
        cell: (info) => (
          <>
            <Button
              variant="info"
              size="sm"
              onClick={() => onEditClick(info.getValue())}
            >
              <i className="fa fa-edit"></i>
            </Button>
            <Button
              variant="danger"
              size="sm"
              onClick={() => onDeleteClick(info.getValue())}
            >
              <i className="fa fa-trash"></i>
            </Button>
          </>
        ),
        header: () => <span>Action</span>,
        footer: (props) => props.column.id,
        enableColumnFilter: false,
      },
    ],
    []
  );

  function onDataLoad() {
    setIsLoading(true);

    api
      .getData(`contract/`)
      .then((res) => {
        strategyList.current = res;

        api
          .getData(`account/`)
          .then((res) => {
            tableData.current = res;
          })
          .catch((err) => {
            console.log(err);
            alert(err.response.data.msg);
          })
          .finally(() => {
            setIsLoading(false);
          });
      })
      .catch((err) => {
        console.log(err);
        alert(err.response.data.msg);
      });
  }

  function onAddNew() {
    setFormCreateAccount("");
    setFormCreateProvider("PTS");
    setFormCreateValid(true);
    setFormCreateStrategyMode("BLACKLIST");
    setFormCreateStrategyList([]);
    setCreateModalShow(!createModalShow);
  }

  function onCreateModalSubmit() {
    setIsSubmitting(true);
    var error = false;
    if (formCreateAccount.trim().length === 0) {
      alert("Account is empty");
      error = true;
    } else if (formCreateProvider.trim().length === 0) {
      alert("Provider is empty");
      error = true;
    }
    if (error === true) {
      setIsSubmitting(false);
      return;
    }

    api
      .postData(`account/`, {
        account: formCreateAccount,
        provider: formCreateProvider,
        isValid: formCreateValid,
        strategyMode: formCreateStrategyMode,
        strategyList: formCreateStrategyList.map((x) => x.id),
      })
      .then((res) => {
        if (res.success === true) {
          alert("Success");
          setCreateModalShow(false);
        } else {
          alert("Fail");
        }
      })
      .catch((err) => {
        console.log(err);
        alert(err.response.data);
      })
      .finally(() => {
        onDataLoad();
        setIsSubmitting(false);
      });
  }

  function onEditClick(id) {
    if (!tableData.current) {
      alert("Error selecting data. Table is empty");
      return;
    }
    var tmp = tableData.current.filter((x) => x._id === id);
    if (tmp.length === 0 || tmp.length > 1) {
      alert("Error selecting data.");
      return;
    }
    tmp = tmp[0];
    setFormEditID(tmp._id);
    setFormEditAccount(tmp.account);
    setFormEditProvider(tmp.provider);
    setFormEditValid(tmp.isValid);
    setFormEditStrategyMode(tmp.strategy_mode);
    setFormEditStrategyList(tmp.strategy_list);
    setEditModalShow(true);
  }

  function onDeleteClick(id) {
    if (!tableData.current) {
      alert("Error selecting data. Table is empty");
      return;
    }
    var tmp = tableData.current.filter((x) => x._id === id);
    if (tmp.length === 0 || tmp.length > 1) {
      alert("Error selecting data.");
      return;
    }
    tmp = tmp[0];
    setFormDeleteID(tmp._id);
    setFormDeleteAccount(tmp.account);
    setFormDeleteProvider(tmp.provider);
    setDeleteModalShow(true);
  }

  function onDeleteModalConfirm() {
    setIsSubmitting(true);
    api
      .deleteData(`account/${formDeleteID}`)
      .then((res) => {
        if (res.success === true) {
          alert("Success");
          setDeleteModalShow(false);
        } else {
          alert("Fail");
        }
      })
      .catch((err) => {
        console.log(err);
        alert(err.response.data);
      })
      .finally(() => {
        onDataLoad();
        setIsSubmitting(false);
      });
  }

  function onEditModalSubmit() {
    setIsSubmitting(true);
    var error = false;
    if (formEditAccount.trim().length === 0) {
      alert("Account is empty");
      error = true;
    } else if (formEditProvider.trim().length === 0) {
      alert("Provider is empty");
      error = true;
    }
    if (error === true) {
      setIsSubmitting(false);
      return;
    }
    api
      .putData(`account/${formEditID}`, {
        account: formEditAccount,
        provider: formEditProvider,
        isValid: formEditValid,
        strategyMode: formEditStrategyMode,
        strategyList: formEditStrategyList.map((x) => x.id),
      })
      .then((res) => {
        if (res.success === true) {
          alert("Success");
          setEditModalShow(false);
        } else {
          alert("Fail");
        }
      })
      .catch((err) => {
        console.log(err);
        alert(err.response.data);
      })
      .finally(() => {
        onDataLoad();
        setIsSubmitting(false);
      });
  }

  return (
    <>
      <Container className="h-100">
        <Row className="mt-3 mb-3">
          <Col>
            <h1 className="text-center">
              Accounts{" "}
              <i
                style={{ cursor: "pointer" }}
                className="fa fa-refresh text-primary"
                onClick={() => !isLoading && onDataLoad()}
              />
            </h1>
          </Col>
        </Row>
        {isLoading === false ? (
          <>
            <Row className="mb-3">
              <Col>
                <Button size="sm" onClick={() => onAddNew()}>
                  Add New
                </Button>
              </Col>
            </Row>
            <Row>
              <Col className="overflow-x-auto">
                <DataTable data={tableData.current} columns={columns} />
              </Col>
            </Row>
            <>
              {/* Create Modal */}
              <Modal
                show={createModalShow}
                size="lg"
                onHide={() => setCreateModalShow(false)}
              >
                <Modal.Header closeButton>
                  <Modal.Title>Create new account</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Form>
                    <Form.Group className="mb-3" controlId="formCreateAccount">
                      <Row>
                        <Col lg="3" className="mt-1">
                          <Form.Label className="fw-bold">Account</Form.Label>
                        </Col>
                        <Col lg="8">
                          <Form.Control
                            type="text"
                            placeholder="Account"
                            value={formCreateAccount}
                            onChange={(e) =>
                              setFormCreateAccount(e.target.value)
                            }
                            disabled={isSubmitting}
                          />
                        </Col>
                      </Row>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formCreateProvider">
                      <Row>
                        <Col lg="3">
                          <Form.Label className="fw-bold">Provider</Form.Label>
                        </Col>
                        <Col lg="8">
                          <Form.Check
                            inline
                            label="DEMO"
                            name="groupCreateProvider"
                            type="radio"
                            value={"PTS"}
                            id="chkCreateProvDemo"
                            checked={formCreateProvider === "PTS"}
                            onChange={(e) =>
                              setFormCreateProvider(e.target.value)
                            }
                            disabled={isSubmitting}
                          />
                          <Form.Check
                            inline
                            label="LIVE"
                            name="groupCreateProvider"
                            type="radio"
                            id="chkCreateProvLive"
                            value={"QOR"}
                            checked={formCreateProvider === "QOR"}
                            onChange={(e) =>
                              setFormCreateProvider(e.target.value)
                            }
                            disabled={isSubmitting}
                          />
                        </Col>
                      </Row>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formCreateValid">
                      <Row>
                        <Col lg="3">
                          <Form.Label className="fw-bold">Is Valid</Form.Label>
                        </Col>
                        <Col lg="8">
                          <Form.Check
                            type="switch"
                            checked={formCreateValid}
                            onChange={(e) =>
                              setFormCreateValid(e.target.checked)
                            }
                            disabled={isSubmitting}
                          />
                        </Col>
                      </Row>
                    </Form.Group>
                    <Form.Group
                      className="mb-3"
                      controlId="formCreateStrategyMode"
                    >
                      <Row>
                        <Col lg="3">
                          <Form.Label className="fw-bold">
                            Strategy Mode
                            <OverlayTrigger
                              placement="left"
                              delay={{ show: 250, hide: 400 }}
                              overlay={
                                <Tooltip id="button-tooltip">
                                  Whitelist: Only allow the selected contracts
                                  <br />
                                  Blacklist: Block the selected contracts
                                </Tooltip>
                              }
                            >
                              <i className="ms-3 fa fa-info-circle"></i>
                            </OverlayTrigger>
                          </Form.Label>
                        </Col>
                        <Col lg="8">
                          <Form.Check
                            inline
                            label="Blacklist"
                            name="groupCreateStrategyMode"
                            type="radio"
                            value={"BLACKLIST"}
                            id="chkCreateStratBlacklist"
                            checked={formCreateStrategyMode === "BLACKLIST"}
                            onChange={(e) =>
                              setFormCreateStrategyMode(e.target.value)
                            }
                            disabled={isSubmitting}
                          />
                          <Form.Check
                            inline
                            label="Whitelist"
                            name="groupCreateStrategyMode"
                            type="radio"
                            id="chkCreateStratWhitelist"
                            value={"WHITELIST"}
                            checked={formCreateStrategyMode === "WHITELIST"}
                            onChange={(e) =>
                              setFormCreateStrategyMode(e.target.value)
                            }
                            disabled={isSubmitting}
                          />
                        </Col>
                      </Row>
                    </Form.Group>
                    <Form.Group
                      className="mb-3"
                      controlId="formCreateStrategyList"
                    >
                      <Row>
                        <Col lg="3">
                          <Form.Label className="fw-bold">
                            Strategy List
                          </Form.Label>
                        </Col>
                        <Col lg="8">
                          <Multiselect
                            value={formCreateStrategyList}
                            defaultValue={[]}
                            dataKey="id"
                            textField="name"
                            onChange={(value) =>
                              setFormCreateStrategyList(value)
                            }
                            data={strategyList.current.reduce((acc, cur) => {
                              acc.push({ id: cur._id, name: cur.name });
                              return acc;
                            }, [])}
                          />
                        </Col>
                      </Row>
                    </Form.Group>
                  </Form>
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    variant="secondary"
                    onClick={() => setCreateModalShow(false)}
                    disabled={isSubmitting}
                  >
                    Close
                  </Button>
                  <Button
                    variant="primary"
                    onClick={() => onCreateModalSubmit()}
                    disabled={isSubmitting}
                  >
                    Save Changes
                  </Button>
                </Modal.Footer>
              </Modal>
              {/* Delete Modal */}
              <Modal
                show={deleteModalShow}
                size="lg"
                onHide={() => setDeleteModalShow(false)}
              >
                <Modal.Header closeButton>
                  <Modal.Title>Delete Contract</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <h5 className="mb-3">Are you sure to delete?</h5>
                  <h6 className="fw-normal">
                    Account:{" "}
                    <span className="fw-semibold">{formDeleteAccount}</span>
                  </h6>
                  <h6 className="fw-normal">
                    Provider:{" "}
                    <span className="fw-semibold">
                      <Badge
                        bg={
                          formDeleteProvider === "PTS" ? "warning" : "success"
                        }
                      >
                        {formDeleteProvider === "PTS" ? "DEMO" : "LIVE"}
                      </Badge>
                    </span>
                  </h6>
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    variant="secondary"
                    onClick={() => setDeleteModalShow(false)}
                    disabled={isSubmitting}
                  >
                    Close
                  </Button>
                  <Button
                    variant="primary"
                    onClick={() => onDeleteModalConfirm()}
                    disabled={isSubmitting}
                  >
                    Confirm
                  </Button>
                </Modal.Footer>
              </Modal>
              {/* Edit Modal */}
              <Modal
                show={editModalShow}
                size="lg"
                onHide={() => setEditModalShow(false)}
              >
                <Modal.Header closeButton>
                  <Modal.Title>Edit Contract</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Form>
                    <Form.Group className="mb-3" controlId="formEditAccount">
                      <Row>
                        <Col lg="3" className="mt-1">
                          <Form.Label className="fw-bold">Account</Form.Label>
                        </Col>
                        <Col lg="8">
                          <Form.Control
                            type="text"
                            placeholder="Account"
                            value={formEditAccount}
                            onChange={(e) => setFormEditAccount(e.target.value)}
                            disabled={isSubmitting}
                          />
                        </Col>
                      </Row>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formEditProvider">
                      <Row>
                        <Col lg="3" className="mt-1">
                          <Form.Label className="fw-bold">Provider</Form.Label>
                        </Col>
                        <Col lg="8">
                          <Form.Check
                            inline
                            label="DEMO"
                            name="groupEditProvider"
                            type="radio"
                            value={"PTS"}
                            id="chkEditProvDemo"
                            checked={formEditProvider === "PTS"}
                            onChange={(e) =>
                              setFormEditProvider(e.target.value)
                            }
                            disabled={isSubmitting}
                          />
                          <Form.Check
                            inline
                            label="LIVE"
                            name="groupEditProvider"
                            type="radio"
                            id="chkEditProvLive"
                            value={"QOR"}
                            checked={formEditProvider === "QOR"}
                            onChange={(e) =>
                              setFormEditProvider(e.target.value)
                            }
                            disabled={isSubmitting}
                          />
                        </Col>
                      </Row>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formEditValid">
                      <Row>
                        <Col lg="3">
                          <Form.Label className="fw-bold">Is Valid</Form.Label>
                        </Col>
                        <Col lg="8">
                          <Form.Check
                            type="switch"
                            checked={formEditValid}
                            onChange={(e) => setFormEditValid(e.target.checked)}
                            disabled={isSubmitting}
                          />
                        </Col>
                      </Row>
                    </Form.Group>
                    <Form.Group
                      className="mb-3"
                      controlId="formEditStrategyMode"
                    >
                      <Row>
                        <Col lg="3">
                          <Form.Label className="fw-bold">
                            Strategy Mode
                            <OverlayTrigger
                              placement="left"
                              delay={{ show: 250, hide: 400 }}
                              overlay={
                                <Tooltip id="button-tooltip">
                                  Whitelist: Only allow the selected contracts
                                  <br />
                                  Blacklist: Block the selected contracts
                                </Tooltip>
                              }
                            >
                              <i className="ms-3 fa fa-info-circle"></i>
                            </OverlayTrigger>
                          </Form.Label>
                        </Col>
                        <Col lg="8">
                          <Form.Check
                            inline
                            label="Blacklist"
                            name="groupEditStrategyMode"
                            type="radio"
                            value={"BLACKLIST"}
                            id="chkEditStratBlacklist"
                            checked={formEditStrategyMode === "BLACKLIST"}
                            onChange={(e) =>
                              setFormEditStrategyMode(e.target.value)
                            }
                            disabled={isSubmitting}
                          />
                          <Form.Check
                            inline
                            label="Whitelist"
                            name="groupEditStrategyMode"
                            type="radio"
                            id="chkEditStratWhitelist"
                            value={"WHITELIST"}
                            checked={formEditStrategyMode === "WHITELIST"}
                            onChange={(e) =>
                              setFormEditStrategyMode(e.target.value)
                            }
                            disabled={isSubmitting}
                          />
                        </Col>
                      </Row>
                    </Form.Group>
                    <Form.Group
                      className="mb-3"
                      controlId="formEditStrategyList"
                    >
                      <Row>
                        <Col lg="3">
                          <Form.Label className="fw-bold">
                            Strategy List
                          </Form.Label>
                        </Col>
                        <Col lg="8">
                          <Multiselect
                            value={formEditStrategyList}
                            defaultValue={[]}
                            dataKey="id"
                            textField="name"
                            onChange={(value) => setFormEditStrategyList(value)}
                            data={strategyList.current.reduce((acc, cur) => {
                              acc.push({ id: cur._id, name: cur.name });
                              return acc;
                            }, [])}
                          />
                        </Col>
                      </Row>
                    </Form.Group>
                  </Form>
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    variant="secondary"
                    onClick={() => setEditModalShow(false)}
                    disabled={isSubmitting}
                  >
                    Close
                  </Button>
                  <Button
                    variant="primary"
                    onClick={() => onEditModalSubmit()}
                    disabled={isSubmitting}
                  >
                    Save Changes
                  </Button>
                </Modal.Footer>
              </Modal>
            </>
          </>
        ) : (
          <Row>
            <Col className="text-center">
              <Spinner />
            </Col>
          </Row>
        )}
      </Container>
    </>
  );
};
export default Accounts;
