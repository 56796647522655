import React, { useState, useEffect } from 'react';
import { Row, Col, Container, Form, Button } from 'react-bootstrap';

const Notfound = () => {
  return (
    <>
      <Container>
        <Row className='mb-3'>
          <Col>
            <h1 className="text-center">404 Page not found!</h1>
          </Col>
        </Row>
      </Container>
    </>
  );
}
export default Notfound;