import React, { useState, useEffect, useRef } from "react";
import {
  Row,
  Col,
  Container,
  Form,
  Button,
  Spinner,
  Modal,
  Badge,
} from "react-bootstrap";

import { api } from "../../services/api";
import DataTable from "../../components/table.tsx";

const Users = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const tableData = useRef();

  const [createModalShow, setCreateModalShow] = useState(false);
  const [formCreateUsername, setFormCreateUsername] = useState("");
  const [formCreatePassword, setFormCreatePassword] = useState("");
  const [formCreateNotify, setFormCreateNotify] = useState(false);
  const [formCreateEmail, setFormCreateEmail] = useState("");

  const [editModalShow, setEditModalShow] = useState(false);
  const [formEditID, setFormEditID] = useState("");
  const [formEditUsername, setFormEditUsername] = useState("");
  const [formEditPassword, setFormEditPassword] = useState("");
  const [formEditNotify, setFormEditNotify] = useState(false);
  const [formEditEmail, setFormEditEmail] = useState("");

  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const [formDeleteID, setFormDeleteID] = useState("");
  const [formDeleteUsername, setFormDeleteUsername] = useState("");

  useEffect(() => {
    onDataLoad();
  }, []);

  const columns = React.useMemo(
    () => [
      {
        accessorKey: "username",
        cell: (info) => info.getValue(),
        header: () => <span>Username</span>,
        footer: (props) => props.column.id,
      },
      {
        accessorKey: "_id",
        cell: (info) => (
          <>
            <Button
              variant="info"
              size="sm"
              onClick={() => onEditClick(info.getValue())}
            >
              <i className="fa fa-edit"></i>
            </Button>
            <Button
              variant="danger"
              size="sm"
              onClick={() => onDeleteClick(info.getValue())}
            >
              <i className="fa fa-trash"></i>
            </Button>
          </>
        ),
        header: () => <span>Action</span>,
        footer: (props) => props.column.id,
        enableColumnFilter: false,
      },
    ],
    []
  );

  function onDataLoad() {
    setIsLoading(true);
    api
      .getData(`user/`)
      .then((res) => {
        tableData.current = res;
      })
      .catch((err) => {
        console.log(err);
        alert(err.response.data.msg);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  function onAddNew() {
    setFormCreateUsername("");
    setFormCreatePassword("");
    setFormCreateNotify(false);
    setCreateModalShow(!createModalShow);
  }

  function onCreateModalSubmit() {
    setIsSubmitting(true);
    var error = false;
    if (formCreateUsername.trim().length === 0) {
      alert("Username is empty");
      error = true;
    } else if (formCreatePassword.trim().length === 0) {
      alert("Password is empty");
      error = true;
    } else if (formCreateNotify === true) {
      if (formCreateEmail.trim().length === 0) {
        alert("Email is empty");
        error = true;
      }
    }
    if (error === true) {
      setIsSubmitting(false);
      return;
    }
    api
      .postData(`user/`, {
        username: formCreateUsername.trim(),
        password: formCreatePassword.trim(),
        notify: formCreateNotify,
        email: formCreateEmail.trim(),
      })
      .then((res) => {
        if (res.success === true) {
          alert("Success");
          setCreateModalShow(false);
        } else {
          alert("Fail");
        }
      })
      .catch((err) => {
        console.log(err);
        alert(err.response.data);
      })
      .finally(() => {
        onDataLoad();
        setIsSubmitting(false);
      });
  }

  function onEditClick(id) {
    if (!tableData.current) {
      alert("Error selecting data. Table is empty");
      return;
    }
    var tmp = tableData.current.filter((x) => x._id === id);
    if (tmp.length === 0 || tmp.length > 1) {
      alert("Error selecting data.");
      return;
    }
    tmp = tmp[0];
    setFormEditID(tmp._id);
    setFormEditUsername(tmp.username);
    setFormEditEmail(tmp.email || "");
    setFormEditNotify(tmp.notify || false);
    setFormEditPassword("");
    setEditModalShow(true);
  }

  function onDeleteClick(id) {
    if (!tableData.current) {
      alert("Error selecting data. Table is empty");
      return;
    }
    var tmp = tableData.current.filter((x) => x._id === id);
    if (tmp.length === 0 || tmp.length > 1) {
      alert("Error selecting data.");
      return;
    }
    tmp = tmp[0];
    setFormDeleteID(tmp._id);
    setFormDeleteUsername(tmp.username);
    setDeleteModalShow(true);
  }

  function onDeleteModalConfirm() {
    setIsSubmitting(true);
    api
      .deleteData(`user/${formDeleteID}`)
      .then((res) => {
        if (res.success === true) {
          alert("Success");
          setDeleteModalShow(false);
        } else {
          alert("Fail");
        }
      })
      .catch((err) => {
        console.log(err);
        alert(err.response.data);
      })
      .finally(() => {
        onDataLoad();
        setIsSubmitting(false);
      });
  }

  function onEditModalSubmit() {
    setIsSubmitting(true);
    var error = false;
    if (formEditUsername.trim().length === 0) {
      alert("Username is empty");
      error = true;
    } else if (formEditNotify === true) {
      if (formEditEmail.trim().length === 0) {
        alert("Email is empty");
        error = true;
      }
    }
    if (error === true) {
      setIsSubmitting(false);
      return;
    }
    api
      .putData(`user/${formEditID}`, {
        password: formEditPassword,
        notify: formEditNotify,
        email: formEditEmail.trim(),
      })
      .then((res) => {
        if (res.success === true) {
          alert("Success");
          setEditModalShow(false);
        } else {
          alert("Fail");
        }
      })
      .catch((err) => {
        console.log(err);
        alert(err.response.data);
      })
      .finally(() => {
        onDataLoad();
        setIsSubmitting(false);
      });
  }

  return (
    <>
      <Container className="h-100">
        <Row className="mt-3 mb-3">
          <Col>
            <h1 className="text-center">
              Users{" "}
              <i
                style={{ cursor: "pointer" }}
                className="fa fa-refresh text-primary"
                onClick={() => !isLoading && onDataLoad()}
              />
            </h1>
          </Col>
        </Row>
        {isLoading === false ? (
          <>
            <Row className="mb-3">
              <Col>
                <Button size="sm" onClick={() => onAddNew()}>
                  Add New
                </Button>
              </Col>
            </Row>
            <Row>
              <Col className="overflow-x-auto">
                <DataTable data={tableData.current} columns={columns} />
              </Col>
            </Row>
            <>
              {/* Create Modal */}
              <Modal
                show={createModalShow}
                size="lg"
                onHide={() => setCreateModalShow(false)}
              >
                <Modal.Header closeButton>
                  <Modal.Title>Create new user</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Form>
                    <Form.Group className="mb-3" controlId="formCreateUsername">
                      <Row>
                        <Col lg="2" className="mt-1">
                          <Form.Label className="fw-bold">Username</Form.Label>
                        </Col>
                        <Col lg="8">
                          <Form.Control
                            type="text"
                            placeholder="Username"
                            value={formCreateUsername}
                            onChange={(e) =>
                              setFormCreateUsername(e.target.value)
                            }
                            disabled={isSubmitting}
                          />
                        </Col>
                      </Row>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formCreatePassword">
                      <Row>
                        <Col lg="2" className="mt-1">
                          <Form.Label className="fw-bold">Password</Form.Label>
                        </Col>
                        <Col lg="8">
                          <Form.Control
                            type="password"
                            placeholder="Password"
                            value={formCreatePassword}
                            onChange={(e) =>
                              setFormCreatePassword(e.target.value)
                            }
                            disabled={isSubmitting}
                          />
                        </Col>
                      </Row>
                    </Form.Group>
                  </Form>
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    variant="secondary"
                    onClick={() => setCreateModalShow(false)}
                    disabled={isSubmitting}
                  >
                    Close
                  </Button>
                  <Button
                    variant="primary"
                    onClick={() => onCreateModalSubmit()}
                    disabled={isSubmitting}
                  >
                    Save Changes
                  </Button>
                </Modal.Footer>
              </Modal>
              {/* Delete Modal */}
              <Modal
                show={deleteModalShow}
                size="lg"
                onHide={() => setDeleteModalShow(false)}
              >
                <Modal.Header closeButton>
                  <Modal.Title>Delete User</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <h5 className="mb-3">Are you sure to delete?</h5>
                  <h6 className="fw-normal">
                    Username:{" "}
                    <span className="fw-semibold">{formDeleteUsername}</span>
                  </h6>
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    variant="secondary"
                    onClick={() => setDeleteModalShow(false)}
                    disabled={isSubmitting}
                  >
                    Close
                  </Button>
                  <Button
                    variant="primary"
                    onClick={() => onDeleteModalConfirm()}
                    disabled={isSubmitting}
                  >
                    Confirm
                  </Button>
                </Modal.Footer>
              </Modal>
              {/* Edit Modal */}
              <Modal
                show={editModalShow}
                size="lg"
                onHide={() => setEditModalShow(false)}
              >
                <Modal.Header closeButton>
                  <Modal.Title>Edit User</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Form>
                    <Form.Group className="mb-3" controlId="formEditUsername">
                      <Row>
                        <Col lg="2" className="mt-1">
                          <Form.Label className="fw-bold">Username</Form.Label>
                        </Col>
                        <Col lg="8">
                          <Form.Control
                            type="text"
                            placeholder="Username"
                            value={formEditUsername}
                            onChange={(e) =>
                              setFormEditUsername(e.target.value)
                            }
                            disabled={true}
                          />
                        </Col>
                      </Row>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formEditPassword">
                      <Row>
                        <Col lg="2" className="mt-1">
                          <Form.Label className="fw-bold">Password</Form.Label>
                        </Col>
                        <Col lg="8">
                          <Form.Control
                            type="password"
                            placeholder="Password"
                            value={formEditPassword}
                            onChange={(e) =>
                              setFormEditPassword(e.target.value)
                            }
                            disabled={isSubmitting}
                          />
                        </Col>
                      </Row>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formEditEmail">
                      <Row>
                        <Col lg="2" className="mt-1">
                          <Form.Label className="fw-bold">Email</Form.Label>
                        </Col>
                        <Col lg="8">
                          <Form.Control
                            type="email"
                            placeholder="Email"
                            value={formEditEmail}
                            onChange={(e) => setFormEditEmail(e.target.value)}
                            disabled={isSubmitting}
                          />
                        </Col>
                      </Row>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formEditNotify">
                      <Row>
                        <Col lg="2" className="mt-1">
                          <Form.Label className="fw-bold">
                            Notification
                          </Form.Label>
                        </Col>
                        <Col lg="8">
                          <Form.Check
                            type="switch"
                            checked={formEditNotify}
                            onChange={(e) =>
                              setFormEditNotify(e.target.checked)
                            }
                          />
                        </Col>
                      </Row>
                    </Form.Group>
                  </Form>
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    variant="secondary"
                    onClick={() => setEditModalShow(false)}
                    disabled={isSubmitting}
                  >
                    Close
                  </Button>
                  <Button
                    variant="primary"
                    onClick={() => onEditModalSubmit()}
                    disabled={isSubmitting}
                  >
                    Save Changes
                  </Button>
                </Modal.Footer>
              </Modal>
            </>
          </>
        ) : (
          <Row>
            <Col className="text-center">
              <Spinner />
            </Col>
          </Row>
        )}
      </Container>
    </>
  );
};
export default Users;
