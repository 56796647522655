import React, { useState } from "react";
import { Row, Col, Container, Form, Button } from "react-bootstrap";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { api } from "../services/api";
import Accounts from "./adminTabs/accounts";
import Contracts from "./adminTabs/contracts";
import Users from "./adminTabs/users";
import TriggerLogs from "./adminTabs/triggerlogs";
import ActiveAccount from "./adminTabs/activeaccount";

const Admin = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const [isLogon, setIsLogon] = useState(false);
  const [tabKey, setTabKey] = useState("active");

  function onLogin() {
    setIsLoggingIn(true);
    api
      .postDataNoRefresh(`user/login`, {
        username: username,
        password: password,
      })
      .then((res) => {
        if (res.access_token && res.refresh_token) {
          localStorage.setItem("access_token", res.access_token);
          localStorage.setItem("refresh_token", res.refresh_token);
          setIsLogon(true);
        } else {
          setIsLogon(false);
          alert("Login fail. Please check ur username and password.");
        }
      })
      .catch((err) => {
        alert(err.response.data);
      })
      .finally(() => {
        setIsLoggingIn(false);
      });
  }

  function onFormKeyDown(event) {
    if (event.code === "Enter") {
      onLogin();
    }
  }

  return (
    <>
      <Container>
        <Row className="mb-3">
          <Col>
            <h1 className="text-center">Admin Page</h1>
          </Col>
        </Row>
        {!isLogon && (
          <Row>
            <Col lg="3"></Col>
            <Col>
              <Form onKeyDown={(e) => onFormKeyDown(e)}>
                <Form.Group className="mb-3" controlId="formUsername">
                  <Form.Label>Username</Form.Label>
                  <Form.Control
                    disabled={isLoggingIn}
                    type="text"
                    placeholder="Username"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                  />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formPassword">
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    disabled={isLoggingIn}
                    type="password"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </Form.Group>
                <Button
                  disabled={isLoggingIn}
                  variant="primary"
                  type="button"
                  onClick={() => onLogin()}
                >
                  Login
                </Button>
              </Form>
            </Col>
            <Col lg="3"></Col>
          </Row>
        )}

        {isLogon && (
          <Tabs
            id="controlled-tab-example"
            activeKey={tabKey}
            onSelect={(k) => setTabKey(k)}
            className="mt-3"
          >
            <Tab eventKey="active" title="Active Account" className="border">
              <ActiveAccount />
            </Tab>
            <Tab eventKey="logs" title="Trigger Logs" className="border">
              <TriggerLogs />
            </Tab>
            <Tab eventKey="contracts" title="Contracts" className="border">
              <Contracts />
            </Tab>
            <Tab eventKey="accounts" title="QST Accounts" className="border">
              <Accounts />
            </Tab>
            <Tab eventKey="users" title="Users" className="border">
              <Users />
            </Tab>
          </Tabs>
        )}
      </Container>
    </>
  );
};
export default Admin;
