import React from "react";
import ComingSoon from "./pages/comingsoon";
// import Home from "./pages/home";
import QST from "./pages/qst";
import Admin from "./pages/admin";
import Debug from "./pages/debug";
import Notfound from "./pages/notfound";
import { Route, Routes } from "react-router-dom";

export default function App() {
  return (
    <Routes>
      <Route path="/" element={<ComingSoon />} />
      <Route path="qst" element={<QST />} />
      <Route path="admin" element={<Admin />} />
      <Route path="as91ms0-ak1-s" element={<Debug />} />
      <Route path="*" element={<Notfound />} />
    </Routes>
  );
}
