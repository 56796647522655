import axios from "axios";

// const BACKEND_URL = "www.rivetrading.com:81/api";
const BACKEND_URL =
  process.env.NODE_ENV === "development"
    ? "http://localhost:5000"
    : process.env.REACT_APP_BACKEND_URL;
const SOCKET_URL =
  process.env.NODE_ENV === "development"
    ? "http://localhost:5000"
    : process.env.REACT_APP_SOCKET_URL;
const QST_API = "wss://127.0.0.1:8888/websocket";
const AXIOS_API = axios.create({ baseURL: BACKEND_URL });

AXIOS_API.interceptors.request.use(
  async (config) => {
    const refresh_token = localStorage.getItem("refresh_token");

    if (refresh_token) {
      await refreshToken();

      const access_token = localStorage.getItem("access_token");
      config.headers.Authorization = `Bearer ${access_token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

async function refreshToken() {
  try {
    const tmpAxios = axios.create({ baseURL: BACKEND_URL });
    const response = await tmpAxios.post(
      "/user/refresh_token",
      {},
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("refresh_token"),
        },
      }
    );
    localStorage.setItem("refresh_token", response.data.refresh_token);
    localStorage.setItem("access_token", response.data.access_token);
  } catch (error) {
    throw error;
  }
}

async function postData(url, data) {
  try {
    const response = await AXIOS_API.post(`${api.BACKEND_URL}/${url}`, data);
    return response.data;
  } catch (error) {
    throw error;
  }
}

async function getData(url) {
  try {
    const response = await AXIOS_API.get(`${api.BACKEND_URL}/${url}`);
    return response.data;
  } catch (error) {
    throw error;
  }
}

async function deleteData(url) {
  try {
    const response = await AXIOS_API.delete(`${api.BACKEND_URL}/${url}`);
    return response.data;
  } catch (error) {
    throw error;
  }
}

async function putData(url, data) {
  try {
    const response = await AXIOS_API.put(`${api.BACKEND_URL}/${url}`, data);
    return response.data;
  } catch (error) {
    throw error;
  }
}

async function postDataNoRefresh(url, data) {
  try {
    const tmpAPI = axios.create({ baseURL: BACKEND_URL });
    const response = await tmpAPI.post(`${api.BACKEND_URL}/${url}`, data);
    return response.data;
  } catch (error) {
    throw error;
  }
}

export const api = {
  QST_API,
  BACKEND_URL,
  SOCKET_URL,
  getData,
  putData,
  deleteData,
  postData,
  postDataNoRefresh,
};
