import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Container, Spinner, Badge, Button } from "react-bootstrap";

import { api } from "../../services/api.js";
import DataTable from "../../components/table.tsx";

const ActiveAccount = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isKilling, setIsKilling] = useState(false);
  const tableData = useRef();
  const strategyList = useRef();

  useEffect(() => {
    onDataLoad();
  }, []);

  const columns = React.useMemo(
    () => [
      {
        accessorKey: "account",
        cell: (info) => info.getValue(),
        header: () => <span>Account</span>,
        footer: (props) => props.column.id,
      },
      {
        accessorKey: "provider",
        cell: (info) => {
          return (
            <Badge bg={info.getValue() === "PTS" ? "warning" : "success"}>
              {info.getValue() === "PTS" ? "DEMO" : "LIVE"}
            </Badge>
          );
        },
        header: () => <span>DEMO/LIVE</span>,
        footer: (props) => props.column.id,
      },
      {
        accessorKey: "activeStrategy",
        cell: (info) => {
          return info.getValue().map((x, i) => (
            <h6 className="fw-normal" key={i}>
              {strategyList.current.find((y) => y._id === x)
                ? strategyList.current.find((y) => y._id === x).name
                : "Deleted"}
            </h6>
          ));
        },
        header: () => <span>Active Strategy</span>,
        footer: (props) => props.column.id,
      },
      {
        accessorKey: "account",
        cell: (info) => (
          <Button
            variant="danger"
            size="sm"
            disabled={isKilling}
            onClick={() => onKill(info.getValue())}
          >
            <i className="fa fa-ban"></i>
          </Button>
        ),
        header: () => <span>Action</span>,
        footer: (props) => props.column.id,
      },
    ],
    []
  );

  let timeoutId;
  function onDataLoad() {
    if (timeoutId) {
      clearTimeout(timeoutId); // Clear the timeout if it exists
    }

    setIsLoading(true);
    api
      .getData(`contract/`)
      .then((res) => {
        strategyList.current = res;

        api
          .getData(`account/active`)
          .then((res) => {
            tableData.current = res;
          })
          .catch((err) => {
            console.log(err);
            alert(err.response.data.msg);
          })
          .finally(() => {
            setIsLoading(false);
          });
      })
      .catch((err) => {
        console.log(err);
        alert(err.response.data.msg);
      });

    timeoutId = setTimeout(() => {
      onDataLoad();
    }, 30000);
  }

  function onKill(account) {
    setIsKilling(true);
    api
      .postData(`kill/${account}`)
      .then(() => {
        alert("Success");
      })
      .catch((err) => {
        alert(err.response.data);
      })
      .finally(() => {
        setIsLoading(false);
        onDataLoad();
      });
  }

  return (
    <>
      <Container className="h-100">
        <Row className="mt-3 mb-3">
          <Col>
            <h1 className="text-center">
              Active Account{" "}
              <i
                style={{ cursor: "pointer" }}
                className="fa fa-refresh text-primary"
                onClick={() => !isLoading && onDataLoad()}
              />
            </h1>
          </Col>
        </Row>
        {isLoading === false ? (
          <>
            <Row>
              <Col className="overflow-x-auto">
                <DataTable data={tableData.current} columns={columns} />
              </Col>
            </Row>
          </>
        ) : (
          <Row>
            <Col className="text-center">
              <Spinner />
            </Col>
          </Row>
        )}
      </Container>
    </>
  );
};
export default ActiveAccount;
